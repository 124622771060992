import { useAuthDispatchers } from "@store/dispatchers";
import { ExpiredTokenChannel } from "@utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useTokenExpired = () => {
  const { handleLogout } = useAuthDispatchers();
  const { onMessageReceive, closeChannel } = new ExpiredTokenChannel();
  const navigate = useNavigate();

  const handleAutoLogout = ({ redirect } = { redirect: true }) => {
    localStorage.setItem("is_forced_sign_out", JSON.stringify(true));
    handleLogout();
    if (redirect) {
      navigate("/login");
    }
  };

  useEffect(() => {
    onMessageReceive((ev) => {
      const message = Object.keys(ev)
        .map((key) => ev[key])
        .join("");
      if (message === "logout") {
        handleAutoLogout();
      }
    });
    return () => closeChannel();
  });

  return {
    onAutoLogout: handleAutoLogout,
  };
};

export default useTokenExpired;
